import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/Layout";

const NotFoundPage = () => {
  return (
    <Layout>
      <main className="m-3">
        <h2 className="text-3xl lg:text-5xl font-semibold mx-auto text-center">Not found</h2>
        <p className="text-center mt-4">
          The page you requested could not be found.
          <br />
          <Link to="/">Back to home page</Link>.
        </p>
      </main>
    </Layout>
  )
}

export default NotFoundPage
